"use client";

import { Transition } from "@headlessui/react";
import { getCookie } from "cookies-next";
import { ChevronDown, XCircle } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import * as React from "react";

import { AccessCheck } from "@/components/access-check";
import { ExaminePlusLink } from "@/components/cta";
import EndsTonight from "@/components/ends-tonight";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import Menu from "@/components/icons/menu";
import { LoginLink } from "@/components/login-link";
import SaleWrapper from "@/components/sale-wrapper";
import SearchInput from "@/components/search/search-input";
import { Badge } from "@/components/ui/badge";
import { Button, buttonVariants } from "@/components/ui/button";
import { MenuUI } from "@/components/ui/context";
import { useUI } from "@/components/ui/context";
import Logo from "@/components/ui/logo";
import { navLinksMobile } from "@/lib/data/navigation";
import { useScrollBlock } from "@/lib/hooks/use-scroll-block";
import { cn } from "@/lib/utils";
import { SubscriptionInfo } from "@/types/index";

interface MobileNavigationProps {
	subscription: SubscriptionInfo;
}

export default function MobileNavigation({ subscription }: MobileNavigationProps) {
	const searchParams = useSearchParams();
	const { displayMenu, openMenu, closeMenu } = useUI();
	const nav = React.useRef(null);
	const [blockScroll, allowScroll] = useScrollBlock();
	const [isShowing, setIsShowing] = React.useState(true);
	const [fullHeight, setFullHeight] = React.useState(nav?.current?.clientHeight);

	const token = getCookie("token");
	const isPaidAd = searchParams.has("paidad") ? searchParams.get("paidad") : null;

	const showLinks = () => {
		setIsShowing(!isShowing);
		setFullHeight(nav.current.clientHeight);
	};

	React.useEffect(() => {
		if (displayMenu) {
			blockScroll();
		} else {
			allowScroll();
		}
	}, [allowScroll, blockScroll, displayMenu]);

	return (
		<>
			<Button
				className="h-[30px] w-8 rounded bg-examine-purple-medium p-1.5 lg:hidden"
				onClick={() => openMenu()}
				aria-label="Open menu"
				aria-expanded="false"
				aria-controls="menu"
			>
				<Menu className="w-full text-white" />
			</Button>

			<section>
				<Transition
					show={displayMenu}
					enter="duration-200 ease-out"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="duration-200 ease-in"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 z-1001 h-screen w-full overflow-y-auto bg-white">
						{/* Header */}
						<div className="sticky top-0 z-20 flex items-center justify-between bg-white px-7 py-4">
							{/* logo */}
							<div className="flex items-center space-x-2">
								<Link href="/">
									<Logo
										className="h-4 w-18 text-examine-purple-500"
										onClick={() => {
											closeMenu();
											document.body.classList.remove("overflow-hidden");
										}}
									/>
								</Link>
								<SaleWrapper
									cta={
										<FreeTrialWrapper>
											<ExaminePlusLink
												onClick={() => {
													document.body.classList.remove("overflow-hidden");
												}}
												trackDetails={{
													trackFor: "trial",
													location: "mobile hamburger menu above search",
													label: "Examine logo <<Try Examine+ for free>>",
												}}
												className="view-page"
											>
												<Badge variant="cyan" size="sm" className="ml-1.5 space-x-1 text-xs">
													Try Examine+ for free
												</Badge>
											</ExaminePlusLink>
										</FreeTrialWrapper>
									}
								>
									<ExaminePlusLink
										onClick={() => {
											document.body.classList.remove("overflow-hidden");
										}}
										trackDetails={{
											trackFor: "sale",
											location: "mobile hamburger menu above search",
											label: "Examine logo <<ON SALE>>",
										}}
									>
										<Badge variant="cyan" className="ml-1.5 space-x-1">
											<span>Examine+</span>
											<span>
												<EndsTonight dayBeforeCta="SALE ENDS TOMORROW" lastDay="LAST DAY OF SALE">
													ON SALE
												</EndsTonight>
											</span>
										</Badge>
									</ExaminePlusLink>
								</SaleWrapper>
							</div>
							<div className="-mr-2">
								<Button
									className="p-1"
									onClick={() => {
										closeMenu();
										document.body.classList.remove("overflow-hidden");
									}}
									variant="text"
								>
									<XCircle className="h-6 w-6" color="#000" />
								</Button>
							</div>
						</div>
						<div className="z-20 mx-auto flex w-full max-w-md items-center gap-4 px-7">
							{token ? (
								<React.Fragment>
									<Link
										href="/members/dashboard/"
										className={cn(buttonVariants(), "block flex-1 rounded-md border text-center")}
										onClick={() => {
											closeMenu();
											document.body.classList.remove("overflow-hidden");
										}}
									>
										Dashboard
									</Link>
									<Link
										href="/members/profile/"
										className={cn(buttonVariants(), "block flex-1 rounded-md border text-center")}
										onClick={() => {
											closeMenu();
											document.body.classList.remove("overflow-hidden");
										}}
									>
										My Profile
									</Link>
								</React.Fragment>
							) : (
								<React.Fragment>
									<LoginLink
										className={cn(buttonVariants(), "block flex-1 rounded-md border text-center")}
									>
										Log in
									</LoginLink>
									<Link
										href="/signup/"
										className={cn(buttonVariants(), "block flex-1 rounded-md border text-center")}
										onClick={() => {
											closeMenu();
											document.body.classList.remove("overflow-hidden");
										}}
									>
										Sign up
									</Link>
								</React.Fragment>
							)}
						</div>
						<SaleWrapper>
							<ExaminePlusLink
								onClick={() => {
									closeMenu();
									document.body.classList.remove("overflow-hidden");
								}}
								trackDetails={{
									trackFor: "sale",
									location: "mobile hamburger menu above search",
									label: "Examine logo <<ON SALE>>",
								}}
								className="mt-5 flex items-center justify-center gap-2 bg-examine-purple-100 p-4 text-gray-900"
							>
								<div className="relative h-5 w-20 md:h-7 md:w-32">
									<Image fill={true} src="/sales/logo.svg" alt="Examine.com" />
								</div>
								<Badge variant="cyan" className="mt-2 space-x-1">
									<EndsTonight dayBeforeCta="SALE ENDS TOMORROW" lastDay="LAST DAY OF SALE">
										ON SALE
									</EndsTonight>
								</Badge>
							</ExaminePlusLink>
						</SaleWrapper>
						<div className="p-5">
							<SearchInput home />
						</div>
						<AccessCheck
							feature={["study_summaries", "examine_database", "supplement_guides"]}
							featureCheck="any"
							cta={
								<div className="p-5 pt-0 text-center text-sm text-gray-500">
									<Link
										href="/examine-ai/"
										className={cn(
											"mx-auto items-center justify-center font-medium",
											buttonVariants({ variant: "premium" })
										)}
										onClick={() => {
											closeMenu();
											document.body.classList.remove("overflow-hidden");
										}}
									>
										Search with ExamineAI
									</Link>
								</div>
							}
						/>
						<MenuUI />
						<div className="border-t border-gray-300 px-7 pb-30 pt-4">
							<Button
								onClick={showLinks}
								type="button"
								className="flex w-full items-center justify-between px-0 py-1 hover:bg-transparent"
								variant="text"
							>
								<span className="font-lora text-22 font-medium text-primary">Examine</span>
								<span className="ml-auto grid h-7 w-7 place-content-center rounded-full bg-examine-purple-100">
									<ChevronDown
										className={cn(
											"h-5 w-5 transition-transform duration-500",
											isShowing && "-rotate-180"
										)}
									/>
								</span>
							</Button>

							<div
								className="relative mt-3 max-w-md overflow-hidden transition-[height] duration-300 md:px-7"
								style={{
									height: `${isShowing ? `${fullHeight}px` : "0"}`,
								}}
							>
								<nav ref={nav} className="space-y-2 leading-9 -tracking-2">
									{navLinksMobile.map((link) => {
										if (isPaidAd && [0, 1, 2, 5, 6, 7].includes(link.id)) return null;

										return (
											<Link
												href={
													link.href === "/plus/" && subscription?.is_active
														? "/plus-info/"
														: link.href
												}
												key={link.id}
												onClick={() => {
													closeMenu();
													document.body.classList.remove("overflow-hidden");
												}}
												className="block "
											>
												{link.name === "Free weekly insights" && !subscription ? (
													<span
													// onClick={(e) => {
													//   e.preventDefault();
													//   showNewsletterOptinModal();
													// }}
													>
														{link.name}
														<span className="mx-1.5">📥</span>
													</span>
												) : link.name === "Free weekly insights" && subscription ? (
													<></>
												) : (
													<>
														{link.name}
														{link.name == "Examine+" && <></>}
													</>
												)}
											</Link>
										);
									})}
									{token && (
										<Link
											className=" rounded pt-10 text-gray-900 hover:text-primary lg:hover:bg-gray-100"
											href="/api/logout/"
											onClick={() => {
												closeMenu();
												document.body.classList.remove("overflow-hidden");
											}}
										>
											Logout
										</Link>
									)}
								</nav>
							</div>
						</div>
						{/* TEMPORARY HACK TO ENSURE THE ENTIRE MENU IS ACCESSIBLE */}
						<span className="h-36 w-36 opacity-0"></span>
					</div>
				</Transition>
			</section>
		</>
	);
}
