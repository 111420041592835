import { ComponentProps } from "react";

const Menu = (props: ComponentProps<"svg">) => (
	<svg {...props} className="w-full text-white" viewBox="0 0 21 15" fill="none">
		<path
			d="M1.06201 1.31836H19.062"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1.06201 7.31836H19.062"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M1.06201 13.3184H19.062"
			stroke="currentColor"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Menu;
