import { ComponentProps } from "react";

export default function User(props: ComponentProps<"svg">) {
	return (
		<svg
			width="26"
			height="25"
			viewBox="0 0 26 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M21.8149 3.66116C19.454 1.30024 16.3149 0 12.9761 0C9.63726 0 6.49815 1.30024 4.13723 3.66116C1.77631 6.02207 0.476074 9.16119 0.476074 12.5C0.476074 15.8388 1.77631 18.9779 4.13723 21.3388C6.49815 23.6998 9.63726 25 12.9761 25C16.3149 25 19.454 23.6998 21.8149 21.3388C24.1758 18.9779 25.4761 15.8388 25.4761 12.5C25.4761 9.16119 24.1758 6.02207 21.8149 3.66116V3.66116ZM5.90115 20.9618C6.3139 17.4103 9.36737 14.6683 12.9761 14.6683C14.8785 14.6683 16.6674 15.4095 18.013 16.7549C19.1498 17.8919 19.8668 19.3764 20.0512 20.9616C18.1343 22.567 15.6662 23.5352 12.9761 23.5352C10.2859 23.5352 7.81803 22.5672 5.90115 20.9618V20.9618ZM12.9761 13.1596C10.8828 13.1596 9.1795 11.4563 9.1795 9.36298C9.1795 7.26948 10.8828 5.56641 12.9761 5.56641C15.0694 5.56641 16.7727 7.26948 16.7727 9.36298C16.7727 11.4563 15.0694 13.1596 12.9761 13.1596ZM21.312 19.7233C20.9387 18.2184 20.1603 16.8308 19.0487 15.7192C18.1484 14.819 17.0834 14.1436 15.9218 13.7203C17.3182 12.7733 18.2375 11.1734 18.2375 9.36298C18.2375 6.46191 15.8772 4.10156 12.9761 4.10156C10.075 4.10156 7.71465 6.46191 7.71465 9.36298C7.71465 11.1744 8.63476 12.7748 10.0323 13.7217C8.96358 14.1111 7.97462 14.7133 7.12376 15.506C5.89161 16.6534 5.03597 18.1166 4.63924 19.7222C2.95906 17.7853 1.94092 15.2594 1.94092 12.5C1.94092 6.41518 6.89125 1.46484 12.9761 1.46484C19.0609 1.46484 24.0112 6.41518 24.0112 12.5C24.0112 15.2599 22.9927 17.7864 21.312 19.7233V19.7233Z"
				fill="currentColor"
			/>
		</svg>
	);
}

const User2 = ({ ...props }) => {
	return (
		<svg
			{...props}
			width="15"
			height="17"
			viewBox="0 0 15 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.58151 4.40905C9.58151 5.12093 9.32996 5.80366 8.88221 6.30703C8.43445 6.81041 7.82716 7.09321 7.19394 7.09321C6.56072 7.09321 5.95344 6.81041 5.50568 6.30703C5.05792 5.80366 4.80638 5.12093 4.80638 4.40905C4.80638 3.69717 5.05792 3.01444 5.50568 2.51106C5.95344 2.00769 6.56072 1.72489 7.19394 1.72489C7.82716 1.72489 8.43445 2.00769 8.88221 2.51106C9.32996 3.01444 9.58151 3.69717 9.58151 4.40905ZM7.19394 8.43528C8.14377 8.43528 9.05471 8.01109 9.72634 7.25603C10.398 6.50096 10.7753 5.47687 10.7753 4.40905C10.7753 3.34122 10.398 2.31714 9.72634 1.56207C9.05471 0.807004 8.14377 0.382813 7.19394 0.382812C6.24411 0.382813 5.33318 0.807004 4.66155 1.56207C3.98992 2.31714 3.6126 3.34122 3.6126 4.40905C3.6126 5.47687 3.98992 6.50096 4.66155 7.25603C5.33318 8.01109 6.24411 8.43528 7.19394 8.43528ZM14.3566 15.1457C14.3566 16.4878 13.1629 16.4878 13.1629 16.4878H1.22503C1.22503 16.4878 0.03125 16.4878 0.03125 15.1457C0.03125 13.8036 1.22503 9.77736 7.19394 9.77736C13.1629 9.77736 14.3566 13.8036 14.3566 15.1457ZM13.1629 15.1403C13.1617 14.8102 12.979 13.817 12.1696 12.9071C11.3913 12.0321 9.92651 11.1194 7.19394 11.1194C4.46018 11.1194 2.99661 12.0321 2.21826 12.9071C1.40887 13.817 1.22742 14.8102 1.22503 15.1403H13.1629Z"
				fill="#FFA7A7"
			/>
		</svg>
	);
};

export { User, User2 };
