const Logo = ({ ...props }) => (
	<svg
		width="700"
		height="144"
		viewBox="0 0 700 144"
		id="examine-logo"
		fill="#6F468E"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_12_7)">
			<path d="M83.5313 19.8714H22.8042V53.6529H81.7675V73.6412H22.8042V120.631H83.5313V140.503H0V0H83.5313V19.8714Z" />
			<path d="M113.39 140.502H86.4277L163.786 51.8984H190.873L113.39 140.502ZM161.644 99.5899L196.417 140.502H170.211L148.289 114.435L161.644 99.5899ZM122.461 84.6279L94.6171 51.8984H120.949L135.69 69.7827L122.461 84.6279Z" />
			<path d="M268.358 51.8999H290.532V140.503H268.358V131.269C259.286 139.217 249.459 143.075 239.002 143.075C225.773 143.075 214.938 138.633 206.245 129.749C197.677 120.749 193.394 109.41 193.394 95.8508C193.394 82.5253 197.677 71.5375 206.245 62.6538C214.308 54.0039 226.151 49.0945 238.372 49.3283C249.459 49.3283 259.412 53.5364 268.232 62.0694L268.358 51.8999ZM215.946 95.9677C215.946 104.501 218.34 111.397 223.253 116.774C227.915 122.034 234.97 124.957 242.278 124.84C250.467 124.84 257.019 122.268 262.058 117.008C267.098 111.631 269.618 104.735 269.618 96.4353C269.618 88.136 267.098 81.2395 262.058 75.8625C257.145 70.6024 249.963 67.6801 242.404 67.9139C235.096 67.797 228.041 70.7193 223.379 75.9794C218.466 81.2395 215.946 87.9022 215.946 95.9677Z" />
			<path d="M308.298 51.899H330.346V60.0813C334.63 55.9902 338.283 53.1848 341.181 51.6652C344.961 50.0287 349.119 49.2105 353.276 49.3274C364.111 49.3274 372.553 53.6524 378.852 62.4192C385.782 53.6524 395.105 49.3274 406.948 49.3274C428.492 49.3274 439.201 61.484 439.201 85.6804V140.502H417.027V91.2912C417.027 82.7581 415.893 76.7967 413.751 73.29C411.484 69.7833 407.704 68.0299 402.412 67.913C396.365 67.913 391.955 70.0171 389.183 74.342C386.412 78.5501 385.026 85.4466 385.026 94.7979V140.619H362.851V91.525C362.851 75.7447 357.938 67.913 348.111 67.913C341.937 67.913 337.402 70.0171 334.504 74.342C331.606 78.667 330.22 85.4466 330.22 94.681V140.502H308.172L308.298 51.899Z" />
			<path d="M453.813 14.9638C453.813 11.4571 455.325 8.06729 458.097 5.72948C463.641 0.586284 472.712 0.469393 478.381 5.72948C481.153 8.18418 482.665 11.574 482.665 15.0807C482.791 18.5875 481.153 22.0942 478.381 24.5489C475.736 27.1205 472.082 28.5232 468.302 28.5232C464.397 28.6401 460.743 27.1205 458.097 24.5489C455.325 22.0942 453.813 18.5875 453.813 14.9638ZM479.263 51.9013V140.505H457.215V51.9013H479.263Z" />
			<path d="M497.158 51.9H519.332V60.0824C527.018 52.952 535.711 49.4453 545.412 49.4453C556.499 49.4453 565.193 52.7183 571.366 59.1472C576.784 64.6411 579.43 73.6417 579.43 86.149V140.503H557.255V91.0584C557.255 82.2916 555.996 76.3302 553.35 72.9404C550.704 69.6674 546.042 67.9141 539.491 67.7972C532.183 67.7972 527.018 70.0181 523.868 74.5768C520.718 79.1356 519.332 86.7335 519.332 97.7212V140.386H497.158V51.9V51.9Z" />
			<path d="M684.251 100.992H615.838C616.468 108.239 618.988 114.084 623.524 118.409C628.059 122.734 634.359 124.955 640.91 124.721C645.698 124.955 650.486 123.669 654.517 121.097C658.045 118.643 662.077 114.201 666.612 107.655L685.259 117.24C682.613 121.448 679.589 125.306 676.188 128.929C673.164 132.085 669.762 134.774 665.856 136.995C662.203 139.099 658.171 140.618 654.013 141.553C649.478 142.605 644.816 143.073 640.154 142.956C625.918 142.956 614.452 138.748 605.885 130.215C597.318 121.682 592.908 110.344 592.908 96.1997C592.908 82.1728 597.066 70.8344 605.507 62.1845C613.949 53.5346 625.036 49.2096 639.021 49.3265C653.131 49.3265 664.219 53.5346 672.408 61.8338C680.597 70.1331 684.629 81.5884 684.629 96.1997L684.251 100.992ZM661.573 84.2769C658.549 73.406 651.116 67.9122 639.273 67.9122C636.753 67.9122 634.107 68.2628 631.713 69.0811C629.445 69.7824 627.177 70.9513 625.288 72.354C623.398 73.7567 621.634 75.5101 620.374 77.4972C618.988 79.6012 617.854 81.9391 617.224 84.2769H661.573Z" />
			<path d="M678.318 30.3864V17.8068H684.693C685.205 17.8068 685.761 17.9545 686.364 18.25C686.966 18.5341 687.477 18.9773 687.898 19.5795C688.33 20.1705 688.545 20.9205 688.545 21.8295C688.545 22.75 688.324 23.5341 687.881 24.1818C687.438 24.8182 686.898 25.3011 686.261 25.6307C685.636 25.9602 685.045 26.125 684.489 26.125H679.886V24.0795H683.636C684.011 24.0795 684.415 23.892 684.847 23.517C685.29 23.142 685.511 22.5795 685.511 21.8295C685.511 21.0568 685.29 20.5341 684.847 20.2614C684.415 19.9886 684.034 19.8523 683.705 19.8523H681.08V30.3864H678.318ZM685.92 24.4545L689.057 30.3864H686.023L682.955 24.4545H685.92ZM683.091 37.8864C681.205 37.8864 679.438 37.5341 677.79 36.8295C676.142 36.125 674.693 35.1477 673.443 33.8977C672.193 32.6477 671.216 31.1989 670.511 29.5511C669.807 27.9034 669.455 26.1364 669.455 24.25C669.455 22.3636 669.807 20.5966 670.511 18.9489C671.216 17.3011 672.193 15.8523 673.443 14.6023C674.693 13.3523 676.142 12.375 677.79 11.6705C679.438 10.9659 681.205 10.6136 683.091 10.6136C684.977 10.6136 686.744 10.9659 688.392 11.6705C690.04 12.375 691.489 13.3523 692.739 14.6023C693.989 15.8523 694.966 17.3011 695.67 18.9489C696.375 20.5966 696.727 22.3636 696.727 24.25C696.727 26.1364 696.375 27.9034 695.67 29.5511C694.966 31.1989 693.989 32.6477 692.739 33.8977C691.489 35.1477 690.04 36.125 688.392 36.8295C686.744 37.5341 684.977 37.8864 683.091 37.8864ZM683.091 34.6136C685 34.6136 686.739 34.1477 688.307 33.2159C689.875 32.2841 691.125 31.0341 692.057 29.4659C692.989 27.8977 693.455 26.1591 693.455 24.25C693.455 22.3409 692.989 20.6023 692.057 19.0341C691.125 17.4659 689.875 16.2159 688.307 15.2841C686.739 14.3523 685 13.8864 683.091 13.8864C681.182 13.8864 679.443 14.3523 677.875 15.2841C676.307 16.2159 675.057 17.4659 674.125 19.0341C673.193 20.6023 672.727 22.3409 672.727 24.25C672.727 26.1591 673.193 27.8977 674.125 29.4659C675.057 31.0341 676.307 32.2841 677.875 33.2159C679.443 34.1477 681.182 34.6136 683.091 34.6136Z" />
		</g>
		<defs>
			<clipPath id="clip0_12_7">
				<rect width="700" height="143.074" fill="white" />
			</clipPath>
		</defs>
	</svg>
);

const LogoWithoutTM = ({ ...props }) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		className="mx-auto h-10"
		x="0px"
		y="0px"
		viewBox="0 0 543.8 122.4"
	>
		<g>
			<g>
				<path fill="#6F468E" d="M66.3,17H18.1v28.9h46.8V63H18.1v40.2h48.2v17H0V0h66.3V17z" />
				<path
					fill="#6F468E"
					d="M90,120.2H68.6L130,44.4h21.5L90,120.2z M128.3,85.2l27.6,35h-20.8l-17.4-22.3L128.3,85.2z M97.2,72.4
          l-22.1-28H96l11.7,15.3L97.2,72.4z"
				/>
				<path
					fill="#6F468E"
					d="M213,44.4h17.6v75.8H213v-7.9c-7.2,6.8-15,10.1-23.3,10.1c-10.5,0-19.1-3.8-26-11.4
          c-6.8-7.7-10.2-17.4-10.2-29c0-11.4,3.4-20.8,10.2-28.4c6.4-7.4,15.8-11.6,25.5-11.4c8.8,0,16.7,3.6,23.7,10.9L213,44.4z
          M171.4,82.1c0,7.3,1.9,13.2,5.8,17.8c3.7,4.5,9.3,7,15.1,6.9c6.5,0,11.7-2.2,15.7-6.7c4-4.6,6-10.5,6-17.6s-2-13-6-17.6
          c-3.9-4.5-9.6-7-15.6-6.8c-5.8-0.1-11.4,2.4-15.1,6.9C173.4,69.5,171.4,75.2,171.4,82.1z"
				/>
				<path
					fill="#6F468E"
					d="M244.7,44.4h17.5v7c3.4-3.5,6.3-5.9,8.6-7.2c3-1.4,6.3-2.1,9.6-2c8.6,0,15.3,3.7,20.3,11.2
          c5.5-7.5,12.9-11.2,22.3-11.2c17.1,0,25.6,10.4,25.6,31.1v46.9h-17.6V78.1c0-7.3-0.9-12.4-2.6-15.4c-1.8-3-4.8-4.5-9-4.6
          c-4.8,0-8.3,1.8-10.5,5.5c-2.2,3.6-3.3,9.5-3.3,17.5v39.2h-17.6V78.3c0-13.5-3.9-20.2-11.7-20.2c-4.9,0-8.5,1.8-10.8,5.5
          c-2.3,3.7-3.4,9.5-3.4,17.4v39.2h-17.5V44.4z"
				/>
				<path
					fill="#6F468E"
					d="M360.2,12.8c0-3,1.2-5.9,3.4-7.9c4.4-4.4,11.6-4.5,16.1,0c0,0,0,0,0,0c2.2,2.1,3.4,5,3.4,8
          c0.1,3-1.2,6-3.4,8.1c-2.1,2.2-5,3.4-8,3.4c-3.1,0.1-6-1.2-8.1-3.4C361.4,18.9,360.2,15.9,360.2,12.8z M380.4,44.4v75.8h-17.5
          V44.4L380.4,44.4z"
				/>
				<path
					fill="#6F468E"
					d="M394.6,44.4h17.6v7c6.1-6.1,13-9.1,20.7-9.1c8.8,0,15.7,2.8,20.6,8.3c4.3,4.7,6.4,12.4,6.4,23.1v46.5h-17.6
          V77.9c0-7.5-1-12.6-3.1-15.5c-2.1-2.8-5.8-4.3-11-4.4c-5.8,0-9.9,1.9-12.4,5.8s-3.6,10.4-3.6,19.8v36.5h-17.6V44.4z"
				/>
				<path
					fill="#6F468E"
					d="M543.1,86.4h-54.3c0.5,6.2,2.5,11.2,6.1,14.9c3.6,3.7,8.6,5.6,13.8,5.4c3.8,0.2,7.6-0.9,10.8-3.1
          c2.8-2.1,6-5.9,9.6-11.5l14.8,8.2c-2.1,3.6-4.5,6.9-7.2,10c-2.4,2.7-5.1,5-8.2,6.9c-2.9,1.8-6.1,3.1-9.4,3.9
          c-3.6,0.9-7.3,1.3-11,1.2c-11.3,0-20.4-3.6-27.2-10.9c-6.8-7.3-10.2-17-10.2-29.1c0-12,3.3-21.7,10-29.1
          c6.7-7.4,15.5-11.1,26.6-11c11.2,0,20,3.6,26.5,10.7c6.5,7.1,9.7,16.9,9.7,29.4L543.1,86.4z M525.1,72.1c-2.4-9.3-8.3-14-17.7-14
          c-2,0-4.1,0.3-6,1c-1.8,0.6-3.6,1.6-5.1,2.8c-1.5,1.2-2.9,2.7-3.9,4.4c-1.1,1.8-2,3.8-2.5,5.8L525.1,72.1z"
				/>
			</g>
		</g>
	</svg>
);

export default Logo;

export { LogoWithoutTM };
