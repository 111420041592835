"use client";

import { getCookie } from "cookies-next";
import useSWR from "swr";

import { CompanyDetails } from "@/types";

import { swrFetcher } from "./fetch-client";

export const useCompanyDetails = () => {
	const token = getCookie("token");

	const { data, isLoading } = useSWR<CompanyDetails>(
		token ? ["/v1/company", token] : null,
		swrFetcher
	);

	return {
		company: data,
		isAdmin: !!data?.id,
		isLoading,
	};
};
