"use client";

import { getCookie } from "cookies-next";
import useSWR from "swr";

import { swrFetcher } from "./fetch-client";

export const useEnterprise = () => {
	const token = getCookie("token");
	const { data, isLoading } = useSWR<any>(token ? ["/v1/subscription", token] : null, swrFetcher);
	const { data: enterpriseData } = useSWR<any>(
		token ? ["/v1/members/settings", token] : null,
		swrFetcher
	);

	const isEnterpriseUser = data?.plan?.name?.includes("Enterprise");
	const enterpriseName = enterpriseData?.enterprise_name;

	return {
		isEnterpriseUser,
		enterpriseName,
		isLoading,
	};
};

export const useIsAdmin = () => {
	const token = getCookie("token");
	const { data, isLoading } = useSWR<any>(token ? ["/v1/company", token] : null, swrFetcher);

	const isAdmin = data?.member_count ? true : false;

	return {
		isAdmin,
		isLoading,
	};
};
