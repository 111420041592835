import { ComponentProps } from "react";

const SearchIcon = (props: ComponentProps<"svg">) => (
	<svg
		{...props}
		id="search-icon"
		viewBox="0 0 21 21"
		fill="currentColor"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.39931 0.476562C4.53652 0.476562 0.580078 4.36527 0.580078 9.14481C0.580078 13.9246 4.53652 17.8131 9.39931 17.8131C14.2624 17.8131 18.2186 13.9246 18.2186 9.14481C18.2186 4.36527 14.2624 0.476562 9.39931 0.476562ZM9.39931 16.2128C5.43419 16.2128 2.20825 13.0421 2.20825 9.14485C2.20825 5.24761 5.43419 2.07686 9.39931 2.07686C13.3644 2.07686 16.5904 5.24757 16.5904 9.14481C16.5904 13.0421 13.3644 16.2128 9.39931 16.2128Z"
			fill="currentColor"
		/>
		<path
			d="M20.3677 18.7937L15.7002 14.2061C15.3822 13.8935 14.8671 13.8935 14.5491 14.2061C14.231 14.5184 14.231 15.0252 14.5491 15.3376L19.2165 19.9251C19.3755 20.0814 19.5837 20.1595 19.7921 20.1595C20.0002 20.1595 20.2086 20.0814 20.3677 19.9251C20.6857 19.6128 20.6857 19.106 20.3677 18.7937Z"
			fill="currentColor"
		/>
	</svg>
);

export default SearchIcon;
